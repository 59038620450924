import { graphql } from 'gatsby';
import * as React from 'react';
import ApartmentsContext from '../utils/context';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { GlobalProvider } from '../context/GlobalContext';
import HomePageBanner from '../components/HomePageBanner';
import FeaturedPromotions from '../components/FeaturedPromotions';
import BookingForm from '../components/BookingForm';
import FeaturedSlider from '../components/FeaturedSlider';
import AmenitiesAndServices from '../components/AmenitiesAndServices';
import FeaturedPages from '../components/FeaturedPages';
import PromotionList from '../components/PromotionList';
import RoomsList from '../components/RoomsList';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Floorplan from '../components/Floorplan';
import ResidencesPageUpperSection from '../components/ResidencesPageUpperSection';

export default function Page(props) {
  const { data, pageContext } = props;
  const { residencePage } = data.umbraco;
  const { langCode, childSlug } = pageContext;

  const getDir = () => (langCode === 'ar' ? 'rtl' : 'ltr');

  const settings = residencePage.settings.items[0].children.items.find(
    (item) => item.contentTypeAlias === 'apartmentsSettings',
  );
  const translations = settings.translations.map((value) => value.content);

  const memo = {
    translations,
    childSlug,
    langCode,
  };

  const values = React.useMemo(() => memo, [memo]);

  const vid = residencePage.featuredVideo.replace('https://www.youtube.com/embed/', '');

  return (
    <ApartmentsContext.Provider value={values}>
      <GlobalProvider>
        <Layout>
          <Seo page={residencePage} lang={langCode} dir={getDir()} />
          <Header
            isHome={residencePage.cssClass.indexOf('isHome') > -1}
            title="Home"
            settings={settings}
            currentPage={{
              id: residencePage.id,
              url: residencePage.url,
              udi: `umb://document/${residencePage.id.replace(/-/g, '')}`,
            }}
            lang={langCode}
            dir={getDir()}
          />

          <ResidencesPageUpperSection
            data={{ ...residencePage, vid }}
            dir={getDir()}
            lang={langCode}
          />
          {residencePage.apartmentsBody && residencePage.apartmentsBody.length > 0
            ? residencePage.apartmentsBody.map((item, index) => {
                if (!item) return null;
                const id = `${item.content.contentTypeAlias}_${index + 1}`;
                switch (item.content.contentTypeAlias) {
                  case 'apartmentsHomePageBanner':
                    return <HomePageBanner data={item.content} key={index} id={id} />;
                  case 'apartmentsFeaturedPromotions':
                    return (
                      <FeaturedPromotions data={item.content} key={index} id={id} dir={getDir()} />
                    );
                  case 'apartmentsBookingForm':
                    return <BookingForm data={item.content} key={index} id={id} dir={getDir()} />;
                  case 'apartmentsFeaturedSlider':
                    return (
                      <FeaturedSlider
                        data={{ ...item.content, currPage: residencePage.url }}
                        key={index}
                        id={id}
                        dir={getDir()}
                        settings={settings}
                      />
                    );
                  case 'apartmentsAmenitiesAndServices':
                    return <AmenitiesAndServices data={item.content} key={index} id={id} />;
                  case 'apartmentsFeaturedPages':
                    return <FeaturedPages data={item.content} key={index} id={id} />;
                  case 'apartmentsPromotionList':
                    return <PromotionList data={item.content} key={index} id={id} dir={getDir()} settings={settings} />;
                  case 'apartmentsRoomsList':
                    return (
                      <RoomsList
                        data={{ ...item.content, currPage: residencePage.url }}
                        key={index}
                        id={id}
                        settings={settings}
                      />
                    );
                  case 'apartmentsFloorplanBlock':
                    return <Floorplan data={item.content} key={index} id={id} dir={getDir()} />;
                  default:
                    return null;
                }
              })
            : null}
          <Footer lang={langCode} settings={settings} dir={getDir()} />
        </Layout>
      </GlobalProvider>
    </ApartmentsContext.Provider>
  );
}

export const query = graphql`
  query ($id: ID!, $lang: String!) {
    umbraco {
      residencePage(id: $id, culture: $lang) {
        ...ResidencePage
      }
    }
  }
`;
